import { LanguageCodes } from './language.model';
import * as SyncfusionLang from './syncfusion/language';
import { registerLocaleData } from '@angular/common';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { LocaleHelper, LocaleManager } from "@bryntum/core-thin";
import { capitalize } from "../../shared/utility/utility";
import { registerLocale } from 'echarts/core';
// Import lingue angular (serve per le pipe native di angular di tipo date etc...)
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
// Import lingue libreria dayjs
import 'dayjs/locale/it';
// Import lingue libreria echarts
import itLang from '../../../assets/i18n/echarts/it';
// Import lingue libreria bryntum
import "@bryntum/schedulerpro-thin/lib/localization/It.js";
import "@bryntum/calendar-thin/lib/localization/It.js";

registerLocaleData(it);
registerLocaleData(en);

registerLocale('it', itLang);

export const setLanguage = (lang: LanguageCodes): void => {
  dayjs.locale(lang);
  dayjs.extend(updateLocale);
  dayjs.updateLocale(lang,{ weekStart: 1 });
  SyncfusionLang.loadAndSetCulture(lang);
  // Set lingua bryntum
  LocaleManager.locale = LocaleHelper.locales[capitalize(lang)];
};
