import { effect, inject, Injectable } from '@angular/core';
import { NgRxEffectsBase } from '../../shared/utility/NgRxUtils';
import { createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, map, mergeMap, take, tap } from 'rxjs';
import { CoreDefaultActions } from './core.actions';
import { GLOBAL_STATE_BROADCAST_DI_TOKEN } from '../broadcast/broadcast.service';
import { BroadcastMessageType } from '../broadcast/broadcast.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { translate } from "@jsverse/transloco";
import * as AuthActions from "../auth/store/auth.actions";

@Injectable({ providedIn: 'any' })
export class CoreEffects extends NgRxEffectsBase {

  readonly #globalBroadcastService = inject(GLOBAL_STATE_BROADCAST_DI_TOKEN);
  readonly #globalBroadcastMessages = toSignal(this.#globalBroadcastService.messagesOfType(BroadcastMessageType.DISPATCH_ACTION), { initialValue: null });

  public checkIfValidBeforeDispatch$ = createEffect(() => this.actions$.pipe(
    ofType(CoreDefaultActions.checkValidBeforeDispatch),
    tap(({ valid, alertMsg }) => {
      if(!valid){
        this.alertService.showAlert(null, alertMsg, false);
      }
    }),
    mergeMap(({ valid, actions }) => valid ? actions : [ CoreDefaultActions.checkValidBeforeDispatchFailure() ])
  ));

  public canDestroyHandler$ = createEffect(() => this.actions$.pipe(
    ofType(CoreDefaultActions.canDestroyHandler),
    exhaustMap(({ obs$, actions }) => obs$.pipe(
      take(1),
      filter(res => res),
      mergeMap(() => actions)
    ))
  ));

  public globalBroadCastActionEmitter$ = createEffect(() => this.actions$.pipe(
    ofType(CoreDefaultActions.dispatchActionAndPublishMessageToBroadcastChannel),
    mergeMap(({ actions }) => {
      actions.forEach(action => {
        this.#globalBroadcastService.publish({ type: BroadcastMessageType.DISPATCH_ACTION, payload: action });
      });
      return actions;
    })
  ));

  public setSessionExpired$ = createEffect(() => this.actions$.pipe(
    ofType(CoreDefaultActions.setSessionExpired),
    // map(() => CoreDefaultActions.showAlertMessage({ title: null, message: translate('sessionExpiredMsg'), isError: false }))
    map(() => {
      const [title, msg, yes, no] = translate(['attention', 'sessionExpiredMsg', 'continued', 'disconnect']);
      return CoreDefaultActions.confirmThenDoAction({
        title, msg, yes, no,
        actions: [],
        noActions: [
          CoreDefaultActions.dispatchActionAndPublishMessageToBroadcastChannel({
            actions: [AuthActions.logout()]
          })
        ]
      });
    })
  ));

  public showAlertMessage$ = createEffect(() => this.actions$.pipe(
    ofType(CoreDefaultActions.showAlertMessage),
    map(({ title, message, isError }) => {
      this.alertService.showAlert(title, message, isError);
    })
  ), { dispatch: false });

  public confirmThenDoAction$ = this.ngrxUtils.showConfirmThenDispatchEffect(CoreDefaultActions.confirmThenDoAction);

  constructor () {
    super();
    effect(() => {
      const payload = this.#globalBroadcastMessages()?.payload;
      if (payload && 'type' in payload) {
        this.store.dispatch(payload);
      }
    }, { allowSignalWrites: true });
  }

}
